import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

import { AppRoutes, EMAIL_REGEX } from '../../utils/constants';
import Loader from '../Loader';
import {
  changePassword,
  finalizeSubscriptionRecurly,
  getUserSubscriptionState,
  UserSubscription,
} from '../../services/requests';
import firebaseAuthErrors from '../../constants/firebase-auth-errors';
import { useAppState } from '../../providers/AppProvider.hooks';
import { auth } from '../../services/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';

function PasswordChange() {
  const { handleLogIn } = useAppState();
  const [isLoading, setLoading] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [restoreUserError, setRestoreUserError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState(false);
  const recurlySubscriptionId = new URLSearchParams(window.location.search).get(
    'id'
  );
  const [userActiveSubscription, setUserActiveSubscription] =
    useState<UserSubscription | null>(null);
  const handleRestoreClick = () => {
    if (!recurlySubscriptionId) {
      setRestoreUserError('No subscription id for finalize');
      return;
    }

    if (!userActiveSubscription) {
      setRestoreUserError('No subscription');
      return;
    }
    if (!EMAIL_REGEX.test(email)) {
      setEmailError(true);
      return;
    }

    if (password.length < 6) {
      setPasswordError(true);
      return;
    }

    setBtnLoading(true);
    const isTest =
      window.location.origin.includes('dev.metricgurus.com') ||
      process.env.NODE_ENV === 'development';

    changePassword({
      email,
      id: userActiveSubscription.id,
      password,
      test: isTest,
    })
      .then((response) => {
        if ('error' in response) {
          if (
            typeof response.error !== 'string' &&
            response.error.code === 'auth/user-not-found'
          ) {
            createUserWithEmailAndPassword(auth, email, password)
              .then((resp) => {
                if (resp.user) {
                  finalizeSubscriptionRecurly(
                    userActiveSubscription.plan_code,
                    recurlySubscriptionId,
                    email,
                    resp.user.uid
                  )
                    .then((res) => {
                      setTimeout(() => {
                        handleLogIn(email, password);
                      }, 3000);
                    })
                    .catch((err) => {
                      console.error(
                        'finalizeSubscriptionRecurly after createUser err',
                        err
                      );
                      setRestoreUserError(JSON.stringify(err));
                    });
                }
              })
              .catch((err) => {
                console.log('err', err);
                setRestoreUserError(JSON.stringify(err));
              });
          } else {
            if (typeof response.error === 'string') {
              setRestoreUserError(response.error);
            } else {
              setRestoreUserError(response.error.message);
            }
          }
        }
        if ('user_id' in response) {
          finalizeSubscriptionRecurly(
            userActiveSubscription.plan_code,
            recurlySubscriptionId,
            email,
            response.user_id
          )
            .then((res) => {
              setTimeout(() => {
                handleLogIn(email, password);
              }, 3000);
            })
            .catch((err) => {
              console.error('finalizeSubscriptionRecurly err', err);
              setRestoreUserError(JSON.stringify(err));
            });
        }
      })
      .catch((error) => {
        console.error('changePassword error', error);
        setRestoreUserError(JSON.stringify(error));
      })
      .finally(() => {
        setTimeout(() => {
          setBtnLoading(false);
        }, 3000);
      });
  };

  useEffect(() => {
    if (recurlySubscriptionId !== '' && recurlySubscriptionId !== null) {
      getUserSubscriptionState({ recurlySubscriptionId })
        .then((res) => {
          if (res.subscriptions) {
            const subscription = res.subscriptions.find(
              (subscription) =>
                ['canceled', 'active'].includes(subscription.status) &&
                subscription.bundle === 'com.huynh.socialmetric'
            );

            if (subscription) {
              setLoading(false);
              setUserActiveSubscription(subscription);
              setEmail(subscription.emails[0]);
            } else {
              alert(
                'No Active SubscriptionIt seems like you don’t have a subscription anymore'
              );
            }
          }
        })
        .catch((err) => {
          console.error('getUserSubscriptionState err', err);
        });
    }
  }, [recurlySubscriptionId]);

  if (isLoading) return <Loader />;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          padding: '24px 24px 34px',
          width: '100%',
          maxWidth: '430px',
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          Create Your Account
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '15px',
            textAlign: 'center',
            marginTop: '16px',
          }}
        >
          You need to set up an account to access the application
        </Typography>

        <Box
          sx={{
            height: '1px',
            background: 'rgb(245,245,245)',
            width: '100%',
            margin: '24px 0',
          }}
        ></Box>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '16px',
            '& input': {
              width: '100%',
              height: '52px',
              background: 'rgb(245,245,245)',
              border: 'none',
              borderRadius: '12px',
              marginBottom: '16px',
              color: theme.palette.text.primary,
              fontFamily: 'Avenir Next',
              fontWeight: 500,
              fontSize: '17px',
              paddingLeft: '23px',
              '&:focus-visible': {
                outline: 'none',
              },
            },
            '& input::placeholder': {
              color: theme.palette.text.secondary,
              fontFamily: 'Avenir Next',
              fontWeight: 500,
              opacity: 0.5,
              fontSize: '17px',
            },
            '& p': {
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              fontSize: '13px',
              marginBottom: '4px',
            },
            '& .textWrapper': {
              display: 'flex',
              justifyContent: 'space-between',
            },
            '& .error': {
              color: 'rgb(253, 37, 69)',
            },
          }}
        >
          <Box className="textWrapper">
            <Typography>Email address</Typography>
            {emailError && (
              <Typography className="error">Wrong email format</Typography>
            )}
          </Box>
          <input
            disabled
            placeholder="Enter your email address"
            type="email"
            style={{
              outline: emailError ? '1px solid rgb(253, 37, 69)' : undefined,
            }}
            value={email}
            onChange={(e) => {
              setEmailError(false);
              setEmail(e.target.value);
            }}
          ></input>
          <Box className="textWrapper">
            <Typography>Password</Typography>
            {passwordError && (
              <Typography className="error">At least 6 symbols</Typography>
            )}
          </Box>
          <input
            placeholder="Enter your password"
            type="text"
            style={{
              outline: passwordError ? '1px solid rgb(253, 37, 69)' : undefined,
            }}
            value={password}
            onChange={(e) => {
              setPasswordError(false);
              setPassword(e.target.value);
            }}
          ></input>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {restoreUserError && (
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontWeight: 400,
                fontSize: '13px',
                margin: '20px',
                color: 'rgb(253, 37, 69)',
                textAlign: 'center',
              }}
            >
              {restoreUserError in firebaseAuthErrors
                ? firebaseAuthErrors[
                    restoreUserError as keyof typeof firebaseAuthErrors
                  ]
                : restoreUserError}
              {restoreUserError === 'auth/email-already-in-use' && (
                <span>
                  {' '}
                  You can try to{' '}
                  <a href={AppRoutes.SIGN_IN}>restore you password</a>
                </span>
              )}
            </Typography>
          )}
          <button
            style={{
              cursor: 'pointer',
              background: '#256BFD',
              fontSize: '17px',
              color: '#fff',
              fontWeight: 600,
              fontFamily: 'Avenir Next',
              textTransform: 'none',
              height: '56px',
              width: '100%',
              borderRadius: '12px',
              border: 'none',
              WebkitTapHighlightColor: 'transparent',
            }}
            onClick={isBtnLoading ? undefined : handleRestoreClick}
          >
            {isBtnLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              ></CircularProgress>
            ) : (
              'Sign up'
            )}
          </button>
        </Box>
      </Box>
    </Box>
  );
}

export default PasswordChange;
